@import "../utils/vars";
@import "../utils/mixins";

:root,
*:not(app-datatable):not(app-datatable *) {
  scrollbar-width: thin !important;
  @include scrollbar($scrollBarColor, transparent);
}

.sentry-error-embed-wrapper {
  display: none !important;
}

signature-pad {
  background-color: $white-color !important;
}

app-hospitals,
app-doctors,
app-education-plan,
app-education-category {
  .clear-all {
    @media screen and (max-width: 600px) {
      flex-shrink: 0;
      margin-left: 15px;
    }
  }
}

label.select select,
input {
  height: 36px;
  border-radius: 3px;
  background-color: $white-color;
  border: 1px solid #CFD0DE;
  padding: 0 7px;
  font-family: $openSansRegular;
  color: #4c5560 !important;
  font-size: 13px;
  transition: $transition;
  @include placeholder {
    font-size: 13px;
    color: #b7bbbf;
  }

  &:focus,
  &:active {
    box-shadow: 0 1px 4px 0 rgba(100, 105, 183, 0.24);
    border-color: $violet-hover;
  }

  &:hover {
    border-color: #ABACBB;
  }
}

input[disabled] {
  background-color: #f1f1f1;
  border-color: #dddee8;

  &:focus,
  &:active {
    box-shadow: none;
  }
}

img {
  width: 100%;
}

button {
  border: 0;
  background: 0 0;

  &[disabled] {
    pointer-events: none !important;
  }
}

.flatpickr-calendar::before,
.flatpickr-calendar::after {
  display: none;
}

.page-wrapper {
  padding: 33px 30px 130px 30px;
  min-width: 320px;
  min-height: calc(100vh - 61px);
  @media screen and (max-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: 600px) {
    padding-top: 19px;
    padding-left: 10px;
    padding-right: 10px;
  }
  @media screen and (max-width: 414px) {
    padding-left: 10px;
    padding-right: 8px;
  }

  &.relative {
    position: relative;
  }
}

.dashboard {
  &.open {
    .header {
      .nav-btn {
        display: none !important;
      }
    }

    .sticky,
    .sticky-block {
      &.sticky {
        left: 0;
        right: 0;
        padding-left: 30px;
        padding-right: 30px;
        width: 100%;
      }
    }
  }

  &-title {
    font-family: $openSansRegular;
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0.8px;
    color: #4c5560;
    text-transform: uppercase;
    padding-bottom: 25px;
    @media screen and (max-height: 767px) {
      padding-bottom: 10px;
    }

    &.mt12 {
      margin-top: 12px;
    }
  }
}

.sticky,
.sticky-block {
  &.sticky {
    position: fixed;
    z-index: 50;
    padding-top: 10px;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #f2f2f8;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    @media screen and (max-width: 1200px) {
      left: 0;
      right: 0;
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 5px;
      right: 100%;
      top: 0;
      bottom: 0;
      background-color: #f2f2f8;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 5px;
      left: 100%;
      top: 0;
      bottom: 0;
      background-color: #f2f2f8;
    }
  }
}

.top-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }

  .cell {
    display: flex;
    align-items: flex-start;

    &:first-child {
      padding-top: 5px;
      @media screen and (max-width: 1200px) {
        order: 2;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: 16px;
      }
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }

    &:last-child {
      @media screen and (max-width: 1200px) {
        order: 1;
        padding-bottom: 20px;
      }
      @media screen and (max-width: 600px) {
        width: 100%;
        justify-content: space-between;
      }
    }

    &.mt10 {
      @media screen and (max-width: 1420px) {
        margin-top: 10px;
      }
    }
  }

  .btn {
    margin-left: 20px;
    @media screen and (max-width: 1200px) {
      margin-left: 0;
      margin-right: 20px;
      text-align: center;
    }
    @media screen and (max-width: 600px) {
      margin-right: 0;
      width: 48.5%;
    }

    i {
      @media screen and (max-width: 600px) {
        display: none;
      }
    }
  }

  .filter-btn {
    display: none;
    font-family: $openSansSemiBold;
    font-size: 13px;
    font-weight: normal;
    color: $violet;
    cursor: pointer;
    transition: $transition;
    padding-top: 0;
    background: #F2F2F8;
    border: 1px solid #CFD0DE;
    box-sizing: border-box;
    border-radius: 3px;
    height: 32px;
    width: 90px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    @media screen and (max-width: 1200px) {
      display: inline-flex;
    }

    i {
      display: inline-block;
      font-size: 9px;
      margin-left: 8px;
      position: relative;
      top: 1px;
    }

    &:hover {
      color: $violet-hover;
    }
  }
}

.breadcrumbs {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  a, span {
    font-family: $openSansSemiBold;
    font-size: 10px;
    line-height: 14px;
    color: #b5b6c0;
    display: inline-block;
    position: relative;
    padding-right: 12px;
    padding-left: 5px;
    transition: $transition;
    @media screen and (max-width: 1200px) {
      margin-bottom: 12px;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 12px;
      font-size: 10px;
    }

    &:hover {
      color: #4c5560;
    }

    &:after {
      content: ' > ';
      position: absolute;
      right: 0;
      top: 1px;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      cursor: default;
      pointer-events: none;

      &:after {
        display: none;
      }

      &:hover {
        color: #b5b6c0;
      }
    }
  }

  span {
    &:hover {
      color: #b5b6c0;
    }
  }
}

.btn {
  padding: 8px 15px;
  height: 32px;
  font-family: $openSansSemiBold;
  font-size: 14px;
  line-height: 1;
  font-weight: normal;
  color: $white-color;
  border-radius: 3px;
  transition: $transition;
  @media screen and (max-width: 600px) {
    padding-left: 5px;
    padding-right: 5px;
  }

  i {
    margin-right: 7px;
    position: relative;
    top: 1px;
  }

  &.violet {
    background-color: $violet;

    &:hover {
      background-color: $violet-hover;
    }

    &[disabled] {
      background-color: $disableButtonBackground;

      &:hover {
        background-color: $disableButtonBackground;
      }
    }
  }

  &.yellow {
    background-color: $yellow-color;

    &:hover {
      background-color: darken($yellow-color, 10%);
    }
  }

  &.green {
    background-color: $green-color;

    &:hover {
      background-color: $green-color-hover;
    }
  }

  &.green-border {
    background-color: transparent;
    border: 1px solid $green-color;
    color: $green-color;

    &:hover {
      background-color: #F6F9F2;
      border-color: $green-color-hover;
      color: $green-color-hover;
    }
  }

  &.violet-border {
    background-color: transparent;
    border: 1px solid $violet;
    color: $violet;

    &:hover {
      background-color: transparent;
      border-color: $violet-hover;
      color: $violet-hover;
    }
  }

  &.red-border {
    background-color: transparent;
    border: 1px solid $red-color;
    color: $red-color;

    &:hover {
      background-color: transparent;
      border-color: $red-color-hover;
      color: $red-color-hover;
    }
  }

  &.red {
    background-color: $red-color;

    &:hover {
      background-color: $red-color-hover;
    }
  }

  &.grey {
    background-color: rgba($black-color, 0.4);

    &:hover {
      background-color: rgba($black-color, 0.5);
    }
  }

  &.neutral {
    background-color: $neutralColor;

    &:hover {
      background-color: $neutralHoverColor;
    }

    &[disabled] {
      background-color: $disableButtonBackground;

      &:hover {
        background-color: $disableButtonBackground;
      }
    }
  }
}

.uppercase-text {
  text-transform: uppercase;
}

.export-btn {
  display: flex;
  align-items: center;
  font-family: $openSansSemiBold;
  font-weight: normal;
  font-size: 12px;
  transition: $transition;

  span {
    display: inline-block;
    padding-left: 8px;
    text-decoration: underline;
  }

  svg {
    display: inline-block;
    height: 24px;
  }

  &.violet {
    color: $violet;
  }

  &:hover {
    opacity: $hoverOpacity;

    span {
      text-decoration: none;
    }
  }

  &[disabled] {
    color: $disableButtonBackground;

    svg {
      path {
        fill: $disableButtonBackground;
      }
    }

    &:hover {
      opacity: 1;
    }
  }
}

.error-msg {
  display: none !important;
  color: $red-color;
  font-size: 11px !important;
}

.has-error {
  span {
    color: $red-color !important;
  }

  input {
    border-color: $red-color !important;
  }

  .error-msg {
    display: block !important;
  }
}

.textarea {
  padding: 0;
  margin: 0;
  display: block;

  textarea {
    width: 100%;
    border-radius: 3px;
    border: 1px solid #cfd0de;
    resize: none;
    padding: 7px;
    transition: $transition;
    font-size: 13px;
    color: #4c5560;
    font-family: $openSansRegular;

    &:focus,
    &:active {
      box-shadow: 0 1px 4px 0 rgba(100, 105, 183, 0.24);
      border-color: $violet-hover;
    }
  }
}

.overlay-blur {
  position: fixed;
  z-index: 101;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.42;
  background-color: rgba(76, 73, 73, 0.42);
  background-color: #4c4949;
}

.radio-item {
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;

    + label {
      position: relative;
      padding-left: 24px;
      cursor: pointer;
      display: inline-block;
      font-family: $openSansRegular;
      font-size: 12px;
      font-weight: normal;
      line-height: 1.33;
      color: #4c5560;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        border: 1px solid #c8c8de;
        border-radius: 50%;
      }

      &:after {
        content: '';
        width: 6px;
        height: 6px;
        background: $violet;
        position: absolute;
        top: 5px;
        left: 5px;
        border-radius: 50%;
        transition: all 0.2s ease;
      }
    }
  }

  [type="radio"]:not(:checked) {
    + label {
      &:hover {
        &:before {
          border: 1px solid #ABACBB;
        }
      }

      &:after {
        opacity: 0;
        transform: scale(0);
      }
    }
  }

  [type="radio"]:checked {
    + label {
      &:before {
        background-color: #e7e8f8;
        border-color: $violet;
      }

      &:after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  label {
    &.error {
      &::before {
        border-color: $red-color !important;
      }
    }
  }

  &.checkbox-view {
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      + label {
        &::before {
          display: inline-block;
          cursor: pointer;
          border-radius: 3px;
        }

        &::after {
          display: block;
          top: 4px;
          left: 6px;
          width: 4px;
          height: 7px;
          background: transparent;
          border-radius: 0;
          border: solid $violet;
          border-width: 0 2px 2px 0;
          transition: initial !important;
          transform: rotate(41deg) !important;
        }
      }
    }
  }
}

.display-none {
  display: none !important;
}

.display-block {
  display: block !important;
}

.cursor-pointer {
  cursor: pointer !important;
  opacity: .9;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.hover-pointer {
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }
}

.show-disable {
  &:disabled {
    opacity: .7;
    cursor: default;
  }
}

ngx-datatable {
  label.select select:focus,
  label.select select:active,
  input:focus,
  input:active {
    box-shadow: none;
    background-color: transparent;
  }
}

.flatpickr-calendar {
  width: 290px;
  @media screen and (max-width: 1420px) {
    max-width: 270px !important;
  }

  &.open {
    &:not(.hasTime) {
      @media screen and (max-width: 600px), (max-height: 600px) {
        position: absolute;
        top: 50px !important;
        left: 0 !important;
        right: 0 !important;
        margin-right: auto !important;
        margin-left: auto !important;
      }
    }

    &.hasTime {
      &:not(.noCalendar) {
        @media screen and (max-width: 600px), screen and (max-height: 750px) {
          position: absolute;
          top: 50px !important;
          left: 0 !important;
          right: 0 !important;
          margin-right: auto !important;
          margin-left: auto !important;
        }
      }
    }

    &.mobile-top {
      @media screen and (max-width: 600px), (max-height: 600px) {
        position: absolute;
        top: 50px !important;
        left: 0 !important;
        right: 0 !important;
        margin-right: auto !important;
        margin-left: auto !important;
      }
    }

    .flatpickr-weekdays {
      @media screen and (max-height: 400px) {
        height: 14px;
      }
    }

    .flatpickr-months {
      @media screen and (max-height: 400px) {
        height: 15px;
      }

      .flatpickr-month {
        @media screen and (max-height: 400px) {
          height: 20px;
        }
      }

      .flatpickr-prev-month,
      .flatpickr-next-month {
        &::after {
          @media screen and (max-height: 400px) {
            top: 0;
          }
        }
      }
    }

    .flatpickr-days {
      @media screen and (max-height: 400px) {
        padding-top: 5px;
      }

      .dayContainer {
        @media screen and (max-height: 400px) {
          grid-gap: 2px;
        }
      }
    }
  }

  &.arrowBottom,
  &.arrowTop {
    &::before,
    &::after {
      @media screen and (max-width: 600px), screen and (max-height: 750px) {
        display: none !important;
      }
    }
  }
}

.display-flex_spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

app-dashboard {
  .dashboard {
    &.fixed {
      height: 100vh !important;
      overflow: hidden !important;
      overscroll-behavior: none;
      touch-action: none;
    }
  }
}

body {
  .scrollDisabled,
  &.scrollDisabled {
    overflow: hidden !important;
    position: relative;
    height: 100vh !important;
    overscroll-behavior: none;
    touch-action: none;
  }
}

.pointer-events-none {
  pointer-events: none !important;
}

.capitalize {
  text-transform: capitalize;
}

.table-select {
  display: block;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    z-index: 5;
    right: 10px;
    top: 14px;
    display: inline-block;
    border: 5px solid transparent;
    border-top: 5px solid #9D9EA8;
  }

  select {
    height: 30px;
    border-radius: 3px;
    background-color: transparent;
    border: 1px solid transparent;
    position: relative;
    padding: 0 30px 0 0;
    font-family: $openSansRegular;
    color: #4c5560;
    font-size: 13px;
  }

  &:hover {
    select {
      border-color: #CFD0DE;
      padding: 0 30px 0 7px;
      background-color: $white-color;
      left: -7px;
    }

    &::before {
      right: 17px;
    }
  }
}

.disabled-change {
  pointer-events: none;
}

.err-message,
.err-msg {
  color: $red-color;
  font-size: 10px;
  width: auto;
  margin-top: 3px;
  font-family: $openSansSemiBold;
  display: block;
}

.err-msg + .err-message,
.err-message + .err-msg {
  display: none !important;
}

.by-date {
  &.showCalendar {
    &::before {
      @media screen and (max-width: 1420px) {
        border-bottom: 5px solid #9D9EA8 !important;
        border-top: 0 !important;
      }
    }
  }
}

.signature-utility-text {
  display: flex;
  align-items: flex-start;
  padding: 8px 16px 8px 12px;
  margin: 0 0 16px 0;
  border-radius: 3px;
  border: 1px solid #7C81CF;
  background-color: #F5F5FF;
  color: $violet;
  font-family: $openSansSemiBold;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;

  svg {
    flex-shrink: 0;
    width: 16px;
    display: inline-block;
    margin-right: 8px;

    path {
      fill: $violet;
    }
  }

  span {
    display: inline-block;
  }
}

.semi-bold {
  font-family: $openSansSemiBold !important;
}

.custom-tooltip {
  position: relative;

  &__data,
  &__text {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    color: transparent;
  }
}

.value-hidden {
  cursor: pointer;
  width: 100%;

  .ng-value,
  .ng-value-label {
    visibility: hidden;
  }

  .ng-select-container {
    .ng-value-container {
      .ng-input {
        transition: inherit;
        padding-left: 0 !important;
        padding-right: 0 !important;

        input {
          transition: inherit;
        }
      }
    }
  }

  .ng-placeholder {
    font-weight: normal;
    font-family: $openSansRegular;

    &:empty {
      &::before {
        content: 'Choose';
      }
    }
  }

  &.has-value {
    .ng-placeholder {
      display: none;
    }
  }

  &.ng-select-opened,
  &.ng-select-focused {
    box-shadow: -2px -2px 5px -2px rgba(40, 40, 46, 0.09), 5px -1px 5px -5px rgba(40, 40, 46, 0.09), 0px 0px 0px -5px rgba(40, 40, 46, 0.09);

    .ng-value-container {
      .ng-input {
        background-color: $white-color;
        box-shadow: 0 2px 11px 1px rgba(40, 40, 46, 0.09);

        input {
          padding-left: 10px !important;
          padding-right: 10px !important;
        }
      }
    }

    .ng-placeholder {
      display: block;
      position: relative;
      left: 10px;
      z-index: 6666;

      &::before {
        content: 'Search';
      }
    }
  }

  &.ng-select-filtered {
    .ng-placeholder {
      display: none;
    }
  }
}

.not-ellipsis {
  text-overflow: inherit !important;

  .ng-select {
    &.ng-select-single {
      .ng-select-container {
        .ng-value-container {
          text-overflow: inherit !important;

          .ng-value {
            text-overflow: inherit !important;
          }
        }
      }
    }
  }
}

@import "../../../app/dashboard/admin-dashboard/ng-select";
@import "../../../app/dashboard/admin-dashboard/flatpickr";
@import "../../../app/new-employee-application/toggle-checkbox";
@import "../../../app/new-employee-application/ng-select";
@import "../../../app/dashboard/settings-list/system-setting/color-picker";
